import { createEditor as createDefaultEditor } from './default'

const factory = {
    default: createDefaultEditor,
}
// eslint-disable-next-line no-restricted-globals, no-undef
const query =
    // eslint-disable-next-line no-restricted-globals, no-undef
    typeof location !== 'undefined' && new URLSearchParams(location.search)
const name = ((query && query.get('template')) ||
    'default') as keyof typeof factory

const createEditor = factory[name]

if (!createEditor) {
    throw new Error(`template with name ${name} not found`)
}

export { createEditor }
