import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { RouterProvider, createHashRouter } from 'react-router-dom'
import Blog from './pages/Blog'
import Documentation from './pages/Documentation'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const router = createHashRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <App />,
    },
    {
        path: '/blog',
        element: <Blog />,
    },
    {
        path: '/documentation',
        element: <Documentation />,
    },
])

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
